<template>
    <div>
        <Header></Header>
        <div class="container mx-auto py-4">
            <breadcrumb></breadcrumb>
            <router-view class="mt-2" :key="$route.path"></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Breadcrumb from '@/components/layout/Breadcrumb.vue';
    import Footer from '@/components/Footer';

    export default {
        name: 'News',

        components: {
            Header,
            Breadcrumb,
            Footer
        }
    };
</script>
